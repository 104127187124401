import React from "react";
import Img from "gatsby-image";
import Section from "../../components/Section/Section";
import "./Statement.scss";

const Statement = ({ statement, backgroundImage }) => {
  return (
    <Section className="Statement">
      <div className="container">
        <div className="row align-items-md-center">
          <div className="col-lg-12">
            <h2
              className="Text--light"
              dangerouslySetInnerHTML={{ __html: statement }}
            />
          </div>
        </div>
      </div>
      <div className="BgImageContainer">
        <Img fluid={backgroundImage?.localFile?.childImageSharp.fluid} alt="Background" />
      </div>
    </Section>
  );
};

export default Statement;
